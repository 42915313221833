var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("van-nav-bar", {
        attrs: {
          title: _vm.profile.goods.name,
          "left-text": "返回",
          "left-arrow": ""
        },
        on: {
          "click-left": function($event) {
            return _vm.$router.push("/")
          }
        }
      }),
      _vm._v(" "),
      _vm.profile
        ? _c(
            "div",
            [
              _vm.profile
                ? _c("van-row", {
                    staticClass: "con",
                    domProps: {
                      innerHTML: _vm._s(_vm.profile.goods.description)
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "van-grid",
        {
          staticClass: "btn",
          attrs: {
            clickable: "",
            "column-num": 1,
            "icon-size": "18px",
            border: false
          }
        },
        [
          !_vm.canPlay
            ? _c("van-grid-item", {
                attrs: { text: "暂停", icon: "pause-circle-o" },
                on: { click: _vm.play }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.canPlay
            ? _c("van-grid-item", {
                attrs: { text: "播放", icon: "play-circle-o" },
                on: { click: _vm.play }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }