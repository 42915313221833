var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app" }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "mobile-icon" }),
          _vm._v(" "),
          _c("p", { staticClass: "title" }, [
            _vm._v("验证当前手机号 " + _vm._s(_vm.mobileStar(_vm.user.phone)))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "description" }, [
            _vm._v("为了你的帐号安全，请输入验证码")
          ])
        ]),
        _vm._v(" "),
        _c(
          "van-cell-group",
          [
            _c("van-field", {
              attrs: {
                center: "",
                clearable: "",
                label: "短信验证码",
                placeholder: "请输入4位验证码",
                type: "number"
              },
              scopedSlots: _vm._u([
                {
                  key: "button",
                  fn: function() {
                    return [
                      _c("Verify-code-btn", {
                        attrs: {
                          slot: "button",
                          type: _vm.type,
                          "btn-msg": "获取验证码"
                        },
                        on: { sendVerifyCode: _vm.sendVerifyCode },
                        slot: "button"
                      })
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.captcha,
                callback: function($$v) {
                  _vm.captcha = $$v
                },
                expression: "captcha"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "actions" },
          [
            _c(
              "van-button",
              {
                staticClass: "btnSubmit",
                attrs: {
                  type: "primary",
                  disabled: _vm.disabled,
                  size: "large"
                },
                on: { click: _vm.cofirm }
              },
              [_vm._v("确定")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }